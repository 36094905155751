// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    /*width: 1920px;*/
    font-family: "Mulish";
    background-color: #FFCB11;
}

.contoh {
    width: 100%;
}

.content-parent{
    display: table;
    height: 100vh;
    width: 100%
}

.content-child{
    display: table-cell;
    vertical-align: middle;
}

.img-logo{
    width: 400px
}

.img-box{
    width: 300px
}

.container  h3 {
    color: #D70000;
}

form label {
    color: #02630B;
}

form button {
    background-color: #D70000;
    color: white;
}
form button:hover {
    background-color: #BC0000;
}

.field-icon {
    float: right;
    margin-top: -25px;
    position: relative;
}

@media (max-width: 500px) {
    .img-logo{
        width: 300px;
    }

    .img-box{
        width: 250px;
    }
}
